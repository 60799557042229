/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { dataMap, fetcher } from '@/data/Content/_BreadcrumbTrail';
import { useExtraRequestParameters } from '@/data/Content/_ExtraRequestParameters';
import { useNextRouter } from '@/data/Content/_NextRouter';
import { getContractIdParamFromContext, getSettings, useSettings } from '@/data/Settings';
import { getUser, useUser } from '@/data/User';
import { getPageDataFromId, usePageDataFromId } from '@/data/_PageDataFromId';
import { ContentProps } from '@/data/types/ContentProps';
import { constructRequestParamsWithPreviewToken } from '@/data/utils/constructRequestParams';
import { getClientSideCommon } from '@/data/utils/getClientSideCommon';
import { getServerCacheScope } from '@/data/utils/getServerCacheScope';
import { getServerSideCommon } from '@/data/utils/getServerSideCommon';
import { expand, shrink } from '@/data/utils/keyUtil';
import useSWR, { unstable_serialize as unstableSerialize } from 'swr';

const DATA_KEY = 'Breadcrumb';

export const getBreadcrumbTrail = async ({ cache, id: _id, context }: ContentProps) => {
	const pageData = await getPageDataFromId(cache, context.query.path, context);
	const { trail } = context.query;
	const { tokenName = '', tokenValue = '', tokenExternalValue = '' } = pageData ?? {};
	const settings = await getSettings(cache, context);
	const user = await getUser(cache, context);
	const { storeId, defaultCatalogId: catalogId, langId } = getServerSideCommon(settings, context);
	const props = {
		trail,
		tokenName,
		tokenValue,
		tokenExternalValue,
		storeId,
		catalogId,
		langId,
		...getContractIdParamFromContext(user?.context),
	};
	const cacheScope = getServerCacheScope(context, user.context);
	const key = unstableSerialize([shrink(props), DATA_KEY]);
	const params = constructRequestParamsWithPreviewToken({ context });
	let rc;

	const value = cache.get(key, cacheScope);
	if (value) {
		rc = await value;
	} else {
		rc = dataMap(await fetcher(false)(props, params), trail as string[]);
		cache.set(key, Promise.resolve(rc), cacheScope);
	}

	return rc;
};

export const useBreadcrumbTrail = (condition = true) => {
	const router = useNextRouter();
	const { trail } = router.query;
	const { settings } = useSettings();
	const { user } = useUser();
	const { storeId, langId, defaultCatalogId: catalogId } = getClientSideCommon(settings, router);
	const params = useExtraRequestParameters();
	const { data: pageData } = usePageDataFromId();
	const { tokenName = '', tokenValue = '', tokenExternalValue = '' } = pageData ?? {};
	const { data, error } = useSWR(
		condition && tokenName && storeId
			? [
					shrink({
						trail,
						tokenName,
						tokenValue,
						tokenExternalValue,
						storeId,
						catalogId,
						langId,
						...getContractIdParamFromContext(user?.context),
					}),
					DATA_KEY,
			  ]
			: null,
		async ([props]) => dataMap(await fetcher(true)(expand(props), params), trail as string[])
	);
	return { breadcrumb: data, uniqueId: tokenValue, error };
};
